<script>
import { SuawMainContent, SuawSection, SuawSummary, SuawInputGroup, SuawHeading, SuawSidebar, SuawParagraph, SuawList, SuawEmptyState } from "@suaw/suaw-component-library";
import { UserProfileByIdView } from "../../operations.gql";
import { bottomOfTheHour } from "@/utils/formatting/dates.js";

export default {
  name: "DashboardProfileView",
  components: {
    SuawMainContent,
    SuawSection,
    SuawSummary,
    SuawInputGroup,
    SuawHeading,
    SuawSidebar,
    SuawParagraph,
    SuawList,
    SuawEmptyState
  },
  props: {
    isDashboard: {
      type: Boolean
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      timestamp: new Date().getTime(),
      resultUserProfileByIdView: {
        avatar_url: "",
        avatar_color: "blue",
        is_avatar_hidden: false,
        first_name: "",
        display_name: "",
        initials: "",
        country_abbrev: "",
        deleted_at: "",
        role: "",
        description_json: { type: "doc", content: [{ type: "paragraph" }] },
        event_calendars_aggregate: {
          aggregate: {
            count: 0
          }
        },
        posts_aggregate: {
          aggregate: {
            count: 0
          }
        },
        organizerChapters: [
          // {
          //   chapter: {
          //     id: "",
          //     photo_url: "",
          //     title: "",
          //     slug: ""
          //   }
          // }
        ],
        memberChapters: [
          // {
          //   chapter: {
          //     id: "",
          //     photo_url: "",
          //     title: "",
          //     slug: ""
          //   }
          // }
        ],
        memberSeries: [
          // {
          //   id: "",
          //   is_organizer: null,
          //   event_series: {
          //     id: "",
          //     slug: "",
          //     title: "",
          //     photo_url: "",
          //     chapter: {
          //       id: "",
          //       slug: ""
          //     }
          //   }
          // }
        ]
      }
    };
  },
  apollo: {
    resultUserProfileByIdView: {
      query: UserProfileByIdView,
      variables() {
        return {
          user_id: this.isDashboard ? this.$auth.user.id : this.$route.params.userId,
          endsAfter: bottomOfTheHour(new Date()).toISOString()
        };
      },
      loadingKey: "loadingQueriesCount",
      skip() {
        return !this.$auth.isAuthenticated && !this.$route.params.userId;
      }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    isCurrentUser() {
      return this.isDashboard || this.$auth.user?.id === this.$route.params.userId;
    },
    isUserOrganizerOrAdmin() {
      return ["organizer", "app_admin"].includes(this.resultUserProfileByIdView?.role);
    },
    isDeletedUserProfile() {
      return !!this.resultUserProfileByIdView.deleted_at;
    },
    summaryHeading() {
      return this.resultUserProfileByIdView.display_name;
    },
    avatarColor() {
      return this.resultUserProfileByIdView.avatar_color ? this.resultUserProfileByIdView.avatar_color : "blue";
    },
    avatarType() {
      return !this.resultUserProfileByIdView.is_avatar_hidden && this.resultUserProfileByIdView.avatar_url ? "picture" : this.avatarColor;
    },
    summaryThumbnail() {
      return !this.resultUserProfileByIdView.is_avatar_hidden && this.avatarType === "picture"
        ? `${this.resultUserProfileByIdView.avatar_url}?t=${this.timestamp}` //add timestamp to see changed avatar photo
        : this.resultUserProfileByIdView.initials;
    },
    summaryTotalPosts() {
      return `${this.resultUserProfileByIdView.posts_aggregate.aggregate.count} Posts`;
    },
    summaryTotalEvents() {
      return `${this.resultUserProfileByIdView.event_calendars_aggregate.aggregate.count} Events`;
    },
    summaryLocation() {
      return this.resultUserProfileByIdView.country_abbrev;
    },
    aboutText() {
      return this.resultUserProfileByIdView.description_json;
    },
    chapterListItems() {
      return this.resultUserProfileByIdView.memberChapters && this.resultUserProfileByIdView.memberChapters.length > 0
        ? this.chapterProps(this.resultUserProfileByIdView.memberChapters)
        : [];
    },
    seriesListItems() {
      return this.resultUserProfileByIdView.memberSeries && this.resultUserProfileByIdView.memberSeries.length > 0
        ? this.seriesProps(this.resultUserProfileByIdView.memberSeries)
        : [];
    }
  },
  methods: {
    chapterProps(chapterItems) {
      return chapterItems.map(x => ({
        text: x.chapter.title,
        picture: x.chapter.photo_url,
        linkText: "View Chapter",
        linkUrl:
          x.chapter.id && x.chapter.slug
            ? {
                name: "Chapter",
                params: {
                  chapterId: x.chapter.id,
                  chapterSlug: x.chapter.slug
                }
              }
            : null
      }));
    },
    seriesProps(seriesItems) {
      return seriesItems.map(x => ({
        text: x.event_series.title,
        picture: x.event_series.photo_url,
        linkText: "View Series",
        linkUrl:
          x.event_series.chapter.slug && x.event_series.id && x.event_series.slug
            ? {
                name: "Series",
                params: {
                  chapterSlug: x.event_series.chapter.slug,
                  eventId: x.event_series.upcoming_event[0].id,
                  seriesSlug: x.event_series.slug
                }
              }
            : null
      }));
    },
    handleButtonClick(userId) {
      if (this.isCurrentUser) {
        this.onEditClicked();
      } else {
        if (this.$auth.isAuthenticated && this.$auth.user.id !== userId) {
          this.$root.$emit("open-direct-message", { otherUserId: userId });
        }
      }
    },
    onEditClicked() {
      this.$router.push({ name: "DashboardProfileEdit" });
    },
    handleDropdownItemClick(item) {
      if (item.text === "Report User Profile") {
        this.onReportUserProfile(item.userId);
      }
      if (item.text === "Report User Avatar") {
        this.onReportUserAvatar(item.userId);
      }
    },
    onReportUserProfile(userId) {
      const complaintContext = {
        context: "Profile",
        userId: userId
      };
      this.$root.$emit("universal-complaint", complaintContext);
    },
    onReportUserAvatar(userId) {
      const complaintContext = {
        context: "Avatar",
        userId: userId
      };
      this.$root.$emit("universal-complaint", complaintContext);
    }
  }
};
</script>

<template>
  <SuawMainContent v-if="!isDeletedUserProfile && !isLoading">
    <SuawSection>
      <SuawSummary
        :id="isCurrentUser ? $auth.user.id : $route.params.userId"
        summary-type="profile"
        :heading="summaryHeading"
        :thumbnail="summaryThumbnail"
        :avatar-type="avatarType"
        :total-posts="summaryTotalPosts"
        :total-events="summaryTotalEvents"
        :location="summaryLocation"
        :show-primary-button="isCurrentUser || isUserOrganizerOrAdmin"
        button-type="primary"
        :button-label="isCurrentUser ? 'Edit Profile' : 'Message User'"
        dropdown-icon="IconMoreHorizontal"
        dropdown-label=""
        :dropdown-items="isCurrentUser ? null : [ { icon: 'IconUserVoice', text: 'Report User Profile', userId: $route.params.userId }, { icon: 'IconUserCircle', text: 'Report User Avatar', userId: $route.params.userId } ]"
        @click="handleButtonClick"
        @dropdown-item-click="handleDropdownItemClick"
      />
    </SuawSection>
    <SuawSection>
      <SuawInputGroup group-style="border" direction="column" field-one-size="0" field-two-size="0">
        <SuawHeading level="4" content="About" />
        <SuawParagraph :text="aboutText" />
      </SuawInputGroup>
      <SuawSidebar>
        <SuawParagraph text="Joined Chapters" weight="bold" />
        <SuawList v-if="chapterListItems.length > 0" :items="chapterListItems" />
        <SuawEmptyState v-else message="No Joined Chapters" />
        <SuawParagraph text="Joined Series" weight="bold" />
        <SuawList v-if="seriesListItems.length > 0" :items="seriesListItems" />
        <SuawEmptyState v-else message="No Joined Series" />
      </SuawSidebar>
    </SuawSection>
  </SuawMainContent>
  <SuawMainContent v-else-if="!isDeletedUserProfile && isLoading">
    <SuawEmptyState message="Loading user profile..." />
  </SuawMainContent>
  <SuawMainContent v-else>
    <SuawEmptyState message="This user's profile has been removed." />
  </SuawMainContent>
</template>
